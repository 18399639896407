$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$font-size: 1em !default;
$font-family: inherit !default;
$text-color: $gray-dark !default;

$version: "4.1.0" !default;
// updated with ../fonts to fix react-scripts@3.3.0 before it was component/react-widgets/*
$font-path: "../fonts" !default; // for referencing Bootstrap CDN font files directly
$img-path: "../images" !default;
$img-busy: url($img-path + "/loading.gif") !default;
$img-busy-lg: url($img-path + "/loader-big.gif") !default;

$widget-bg: $input-bg !default;
$widget-border: $input-border-color !default;

$icon-color: $gray-700 !default;

//
// Widget States
//
$state-bg-select: $primary !default;
$state-border-select: $state-bg-select !default;
$state-color-select: $white !default;

$state-bg-hover: $dropdown-link-hover-bg !default;
$state-border-hover: $dropdown-link-hover-bg !default;
$state-color-hover: $dropdown-link-hover-color !default;

$state-bg-focus: $dropdown-link-active-bg !default;
$state-border-focus: $dropdown-link-active-bg !default;
$state-color-focus: $dropdown-link-hover-bg !default;

//
// Buttons
//
$btn-bg: $white !default;
$btn-color: $text-color !default;
$btn-border: $gray-400 !default;

$btn-bg-hover: $state-bg-hover !default;
$btn-border-hover: $state-border-hover !default;
$btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !default;

$select-btn-bg: $btn-bg !default;
$select-btn-color: $btn-color !default;
$select-btn-border: $btn-border !default;

$select-btn-bg-hover: $btn-bg-hover !default;
$select-btn-border-hover: $btn-border-hover !default;
$select-btn-active-box-shadow: $btn-active-box-shadow !default;

//
// Picker inputs
//
$input-color: $gray !default;
$input-padding-horizontal: 0.857em !default;
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;

$input-bg: $widget-bg !default;
$input-color-placeholder: #999 !default;

$input-border: $widget-border !default;
$input-border-width: 1px !default;
$input-border-radius: $border-radius !default;

$input-bg-disabled: $gray-lighter !default;
$input-border-disabled: $input-border !default;

$input-bg-hover: $gray-lighter !default;
$input-border-hover: $input-border !default;

$input-bg-focus: $input-bg !default;
$input-border-focus: $state-border-focus !default;

$input-focus-color: rgba(
  red($input-border-focus),
  green($input-border-focus),
  blue($input-border-focus),
  0.6
) !default;
$input-focus-box-shadow: 0 0 8px $input-focus-color !default;

$input-bg-color-autofill: rgb(250, 255, 189) !default;
$input-color-autofill: rgb(0, 0, 0) !default;
//
// List
//
$list-font-size: $font-size !default;

$list-bg-hover: $state-bg-hover !default;
$list-border-hover: $state-border-hover !default;
$list-color-hover: $state-color-hover !default;

$list-bg-focus: $state-bg-focus !default;
$list-border-focus: $state-border-focus !default;
$list-color-focus: $state-color-focus !default;

$list-bg-select: $state-bg-select !default;
$list-border-select: $state-border-select !default;
$list-color-select: $state-color-select !default;

$list-text-color: $text-color !default;
$list-padding-vertical: $dropdown-item-padding-y !default;
$list-padding-horizontal: $dropdown-item-padding-x !default;

//
// Popup
//
$popup-bg: $widget-bg !default;
$popup-border: $widget-border !default;
$popup-border-radius: $border-radius-sm !default;
$popup-box-shadow-offset: 6px !default;
$popup-box-shadow: $box-shadow-default !default;
$popup-box-shadow-up: $box-shadow-default-inverse !default;

$popup-zindex: 1005 !default;
$popup-zindex-focused: $popup-zindex + 1 !default;

//
// Calendar
//
$calendar-bg: $widget-bg !default;
$calendar-cell-align: center !default;
$calendar-cell-padding: 0.25em !default;
$calendar-cell-color: $btn-color !default;
$calendar-cell-border-radius: $input-border-radius !default;

$calendar-cell-bg-hover: $state-bg-hover !default;
$calendar-cell-border-hover: $state-border-hover !default;
$calendar-cell-color-hover: $state-color-hover !default;

$calendar-cell-bg-focus: $state-bg-focus !default;
$calendar-cell-border-focus: $state-border-focus !default;
$calendar-cell-color-focus: $state-color-focus !default;

$calendar-cell-bg-select: $state-bg-select !default;
$calendar-cell-border-select: $state-border-select !default;
$calendar-cell-color-select: $state-color-select !default;

//
// Multiselect
//
$multiselect-gutter-pt: 0.115 !default;
$multiselect-tag-padding-left: 0.35em !default;
$multiselect-tag-padding-right: 0.35em !default;
$multiselect-tag-color: $dropdown-link-color !default;
$multiselect-tag-border: darken($dropdown-link-hover-bg, 15%) !default;
$multiselect-tag-bg: $dropdown-link-hover-bg !default;
$multiselect-tag-height-pt: 1 - ($multiselect-gutter-pt * 2) !default;
$multiselect-tag-gutter: calc(
  #{$input-height / 5} - #{$input-border-width}
) !default;
$multiselect-tag-height: ($input-height / 1.5) !default;
$multiselect-tag-border-radius: $border-radius-sm !default;

$multiselect-tag-bg-hover: $state-bg-hover !default;
$multiselect-tag-border-hover: $state-border-hover !default;
$multiselect-tag-color-hover: $state-color-hover !default;
